import React from 'react';
import styled from 'styled-components';

const LinearProgressDiv = props => {
    const { progress, 
        disabledState,
         odd, hover } = props;
    return (
        <ProgressWrapper>
            <LinearProgressWrapper odd={odd} hover={hover}>
                <LinearProgress porcentaje={progress} disabledState={disabledState} />
            </LinearProgressWrapper>
            <Percentage disabledState={disabledState}>{progress}%</Percentage>
        </ProgressWrapper>
    );
};

export default LinearProgressDiv;

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    width: 100%;
`;

export const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 8px;
    background-color: ${({ odd }) => (odd ? '#eaeaea' : '#ffffff')};
    ${({ hover }) =>
        hover &&
        `
        background-color: #ffffff;
    `}
`;

export const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${props => `${props.porcentaje}%`};
    vertical-align: middle;
    height: 7px;
    background-color: ${props => (props.disabledState ? '#A8A8A8' : '#3bd39c')};
`;

export const Percentage = styled.span`
    font-size: 0.9rem;
    color: ${props => (props.disabledState ? '#A8A8A8' : '#222222')};
    flex-wrap: wrap;
`;
