import React, { useEffect } from 'react';
import { ContainerModalFilterForm, ProgressDiv, RowWrapper, SelectStyled, Subtitle } from '../styled-components/corporate.styled.components';
import Text from '../../../shared/common/Text';
import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem } from '@material-ui/core';
import { useState } from 'react';

import { ButtonFilledDark, ButtonOutlinedDark } from '../../../styled-components/buttons.styled.components';

const FilterForm = props => {
    const { filtersSelected, initialProgram, initialProgress, handleClean, optionsPrograms } = props;

    //Filtro por nombre de programa y progresso de cursado
    const [program, setProgram] = useState(initialProgram);
    const [state, setState] = useState(initialProgress);

    const { pending, in_progress, finished } = state;

    const progressOptions = [
        { name: 'pending', label: 'Por iniciar', checked: pending },
        { name: 'in_progress', label: 'En curso', checked: in_progress },
        { name: 'finished', label: 'Finalizado', checked: finished },
    ];

    const handleChangeProgram = event => {
        setProgram(event.target.value);
    };

    const handleChangeProgress = event => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleFilters = () => {
        if (pending || in_progress || finished || program) {
            filtersSelected(state, program);
        }
    };

    return (
        <>
            <ContainerModalFilterForm>
                <Text fontSize="16px" fontWeight="700" fontcolor="#222222">
                    Filtros de búsqueda
                </Text>

                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <Text fontSize="14px" fontWeight="400" fontcolor="#222222">
                            Programa
                        </Text>

                        <SelectStyled labelId="open-select-label" id="open-select" value={program} onChange={handleChangeProgram}>
                            {optionsPrograms.map((value, index) => (
                                <MenuItem value={value.name} key={index}>
                                    {value.name}
                                </MenuItem>
                            ))}
                        </SelectStyled>

                        <ProgressDiv>
                            <Text fontSize="14px" fontWeight="400" fontcolor="#222222">
                                Estado del programa
                            </Text>

                            {progressOptions.map(option => (
                                <FormControlLabel
                                    key={option.name}
                                    control={<Checkbox checked={option.checked} onChange={handleChangeProgress} name={option.name} color="primary" />}
                                    label={option.label}
                                />
                            ))}
                        </ProgressDiv>
                    </FormGroup>
                </FormControl>

                <RowWrapper>
                    <ButtonOutlinedDark variant="outlined" color="primary" size="small" onClick={handleClean}>
                        Limpiar filtros
                    </ButtonOutlinedDark>
                    <ButtonFilledDark variant="contained" color="primary" size="small" onClick={handleFilters}>
                        Aplicar filtros
                    </ButtonFilledDark>
                </RowWrapper>
            </ContainerModalFilterForm>
        </>
    );
};

export default FilterForm;
