import axios from 'axios';

// GET PARTICIPANTS
export async function getParticipants({ ondemand_id }) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/${ondemand_id}/corporate_plans`);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}
