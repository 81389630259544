import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { GroupRounded, PersonRounded } from '@material-ui/icons';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VideoLibraryRoundedIcon from '@mui/icons-material/VideoLibraryRounded';
import ChecklistRtlRoundedIcon from '@mui/icons-material/ChecklistRtlRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import StickyNote2RoundedIcon from '@mui/icons-material/StickyNote2Rounded';
import { Icon } from '@iconify/react/dist/iconify.js';
import AlabRojo from '../../../assets/AcropolisLabRojo.png';
import { Divider } from '@material-ui/core';


const Aside = props => {
    const { isOpen , handleToggle } = props;

    const {
        // habilita_workshop,
        // habilita_dracma,
        // habilita_evento,
        // habilita_directorio,
        // habilita_networking,
        // habilita_vencimiento,
        // habilita_oferta_educativa,
        habilita_desafio,
        habilita_cursos_adicionales,
        // habilita_actividad_correccion,
        // habilita_enviar_certificados_auto,
        habilita_rutas_formativas,
        // habilita_blog_aden,
        habilita_blog_empresa,
        habilita_cursos_empresa,
        // habilita_cursos_aden,
        // pregunta_desafio_dracma,
        // pregunta_personalizacion_perfil,
        // necesita_nota_certificado,
        // nota_certificado,
        // url_desafio,
        // email_cuenta_certificado,
        is_incompany, //a solicitud de Juan Francisco Indelicato se agrega la validación de si es incompany, se habilita todo el contenido. Se elimina la seccion de configuración
        imagen,
        // detalle,
        display_name,
    } = useSelector(state => state.user.user);

    const [hoverMenu, setHoverMenu] = useState(false);
    const [openAdvanceMenu, setOpenAdvanceMenu] = useState(false);
    const [openContentMenu, setOpenContentMenu] = useState(false);
    const isAnaldex = display_name.includes('Analdex'); //solo éste Incompany requiere la no inclusión del logo de ADEN

    const menuAvance = [
        {
            title: 'Usuarios',
            to: '/users',
            icon: <PersonRounded />,
            active: true,
        },

        {
            title: 'Grupos',
            to: '/groups',
            icon: <GroupRounded />,
            active: true,
        },
        {
            title: 'Analítica general',
            to: '/analytics',
            icon:  <AnalyticsRoundedIcon />,
            active: true,
        },
        {
            title: 'Planes corporativos',
            to: '/corporate-plans',
            icon: <Icon icon="mdi:user-tie" width="24" height="24" />,
            active: true,
        },
    ];

    const menuContenido = [
        {
            title: 'Recursos',
            to: '/resources',
            icon: <StickyNote2RoundedIcon />,
            active: !!habilita_cursos_empresa || is_incompany,
        },
        {
            title: 'Cursos',
            to: '/content',
            icon: <MenuBookRoundedIcon />,
            // icon: <Icon icon="ion:book" />,
            active: !!habilita_cursos_adicionales || is_incompany,
        },
        {
            title: 'Rutas',
            to: '/subjects',
            icon: <RouteRoundedIcon />,
            active: !!habilita_rutas_formativas || is_incompany,
        },

        {
            title: 'Desafìos',
            to: '/',
            icon: <VideoLibraryRoundedIcon />,
            active: !!habilita_desafio || is_incompany,
        },
        {
            title: 'Instrumentos',
            to: '/',
            icon: <ChecklistRtlRoundedIcon />,
            active: true,
        },
        {
            title: 'Contenido extra',
            to: '/additional-content',
            icon: <CampaignRoundedIcon />,
            active: !!habilita_blog_empresa || is_incompany,
        },
    ];

    // RETURN
    return (
        <AsideContainer
            isOpen={isOpen || hoverMenu}
            onMouseEnter={() => setHoverMenu(true)}
            onMouseLeave={() => {
                setHoverMenu(false);
                if (!isOpen) {
                    setOpenAdvanceMenu(false);
                    setOpenContentMenu(false);
                }
            }}>
            <MenuWrapper>
                <ToggleSection hoverMenu={hoverMenu}>
                    <IconButtonEdited
                        isOpen={isOpen}
                        onClick={() => {
                            handleToggle();
                        }}>
                        <Icon icon="mi:menu" width="30px" height="30px" />
                    </IconButtonEdited>

                    <ImagesContainer isOpen={isOpen || hoverMenu}>
                        {isAnaldex ? (
                            <a href="/">
                                <FakeLogo src={imagen} alt="logo"  isAnaldex={isAnaldex}/>
                            </a>
                        ) : (
                            <>
                                <a href="/">
                                    <FakeLogo src={AlabRojo} alt="logo"  isAnaldex={false}/>
                                </a>
                                <FakeDivider orientation="vertical" flexItem />
                                <FakeLogo src={imagen} alt="logo_company"  isAnaldex={false}/>
                            </>
                        )}
                    </ImagesContainer>
                </ToggleSection>
                <ButtonsSection hoverMenu={hoverMenu}>
                    <Link isOpen={isOpen || hoverMenu} isPrincipal={true} href="/">
                        <HomeRoundedIcon />
                        <p>Inicio</p>
                    </Link>
                    <Link
                        isOpen={isOpen || hoverMenu}
                        openIcon={openAdvanceMenu}
                        isPrincipal={true}
                        onClick={() => setOpenAdvanceMenu(!openAdvanceMenu)}>
                        <ManageAccountsRoundedIcon />
                        <p>
                            Control de avance
                            <Icon icon="tabler:chevron-up" width="20px" height="20px" />
                        </p>
                    </Link>
                    <SubLinksWrapper isShown={openAdvanceMenu} height="50%" maxHeight="170px">
                        <Triangle />
                        {menuAvance.map((link, index) => {
                            if (link.active) {
                                return (
                                    <Link href={link.to} key={index} isOpen={isOpen || hoverMenu}>
                                        {link.icon}
                                        <p>{link.title}</p>
                                    </Link>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </SubLinksWrapper>
                    <Link
                        isOpen={isOpen || hoverMenu}
                        openIcon={openContentMenu}
                        isPrincipal={true}
                        onClick={() => setOpenContentMenu(!openContentMenu)}>
                        <EditRoundedIcon />
                        <p>
                            Editor de contenido
                            <Icon icon="tabler:chevron-up" width="20px" height="20px" />
                        </p>
                    </Link>
                    <SubLinksWrapper isShown={openContentMenu} height="65%" maxHeight="280px">
                        <Triangle />
                        {menuContenido.map((link, index) => {
                            if (link.active) {
                                return (
                                    <Link href={link.to} key={index} isOpen={isOpen || hoverMenu}>
                                        {link.icon}
                                        <p>{link.title}</p>
                                    </Link>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </SubLinksWrapper>
                </ButtonsSection>
            </MenuWrapper>
        </AsideContainer>
    );
};

export default Aside;

const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${p => (p.isOpen ? '280px' : '70px')};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 905;
    transition: all 0.5s ease;

    p {
        visibility: ${p => (p.isOpen ? 'visible' : 'hidden')};
        transition: visibility 0.5s ease;
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    padding-top: 0.5rem;
`;

const ToggleSection = styled.div`
    width: 90%;
    height: 100%;
    min-height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ImagesContainer = styled.div`
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    width: 70%;
    max-width: ${props => (props.isOpen ? '100%' : '0px')};
    overflow: hidden;
    transition: max-width 0.5s ease;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const FakeLogo = styled.img`
    max-width: ${props => (props.isAnaldex ? '100%' : '75px')};
    background-size: cover;
    background-position: center center;
`;

const FakeDivider = styled(Divider)`
    height: 35px;
    max-width: 4px;
    margin: 0px;
`;

const ButtonsSection = styled.div`
    width: 95%;
    height: 100%;
    display: block;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem 0 0;
`;

const SubLinksWrapper = styled.div`
    position: relative;
    width: 100%;
    max-height: ${p => (p.isShown ? p.maxHeight : '0px')};
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    background-color: #f1f1f1;
    margin-top: ${p => p.isShown && '1.2rem'};
    border-radius: 0 0 20px 20px;
    padding-bottom: ${p => p.isShown && '1rem'};
    transition: all 0.5s ease;

    div {
        display: ${p => (p.isShown ? 'flex' : 'none')};
    }
`;

const Triangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #f1f1f1;
    top: -0.9rem;
    right: 0.5rem;
`;

const Link = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    width: ${p => (p.isOpen ? '90%' : '60%')};
    min-height: ${props => props.isPrincipal && '40px'};
    padding-left: 1.8rem;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    transition: width 0.5s ease;
    margin-top: 1rem;
    color: ${props => (props.openIcon ? 'var(--primary-one)' : 'var(--primary-two)')};
    background-color: ${props => (props.openIcon ? 'var(--secondary-two)' : !props.isPrincipal ? 'transparent' : 'var(--secondary-one)')};

    p {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;

        svg {
            cursor: pointer;
            transform: ${props => !props.openIcon && 'rotate(180deg)'};
            transition: all 0.5s ease;
            margin-right: 8px;
        }
    }

    svg {
        width: 24px;
        height: 24px;
    }

    :hover {
        color: var(--primary-one);
        background-color: var(--secondary-two);
    }
`;

const IconButtonEdited = styled.button`
    width: fit-content;
    transition: all 0.5s ease;
    transform: ${props => props.isOpen && 'rotate(90deg)'};
    background-color: var(--secondary-one);
`;
