import { Select } from '@material-ui/core';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 1.5rem;
`;

export const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

export const ChipState = styled.span`
    font-size: 0.8rem;
    text-transform: capitalize;
    padding: 0.25rem 0.75rem;
    border-radius: 50px;
    width: max-content;
    color: #ffffff;
    background-color: ${p =>
        p.background === 'activo'
            ? '#1e8065'
            : p.background === 'pendiente'
            ? '#f2ab1f'
            : p.background === 'bloqueado'
            ? '#b31531'
            : p.background === 'vencido' && '#b31531'};
`;

//modal de filtros

export const ContainerModalFilterForm = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    min-width: 768px;
    max-height: 75vh;
`;

export const ProgressDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    p {
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 1rem;
        padding-left: 1rem;
    }

    label {
        padding-left: 1rem;

        span:first-of-type {
            background-color: transparent;
            width: 1rem;
            height: 1rem;
            padding: 0.75rem;
            margin: 0.2rem;
            border-radius: 100px;
            :hover {
                background-color: #f1f1f1;
            }
            &.Mui-checked {
                background-color: #dcdcdc;
            }
        }

        span:last-of-type {
            padding-left: 0.5rem;
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
`;

export const SelectStyled = styled(Select)`
    border: 1px solid #a8a8a8;
    border-radius: 12px;
    padding: 0.5rem;
    margin-top: 0.5rem;

    &.MuiInput-underline:after {
        display: none !important;
        border: none !important;
    }

    &.MuiInput-underline:before {
        display: none !important;
        border: none !important;
    }
`;
