import React, { useEffect, useState } from 'react';
import Layout from '../../shared/layout/Layout';
import Header from '../../shared/layout/header/Header';
import { HeaderWrapper, RowWrapper } from './styled-components/corporate.styled.components';
import InputIcon from '../../shared/common/InputIcon';
import { useSelector } from 'react-redux';
import UsersSkt from '../Users/components/UsersSkt';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ButtonFilledDark } from '../../styled-components/buttons.styled.components';
import TableCorporatePlans from './components/TableCorporatePlans';
import ModalForm from '../../shared/common/ModalForm';
import FilterForm from './components/FilterForm';
import useFilterCorporateParticipants from './hooks/useFilterCorporateParticipants';
import { getParticipants } from '../../redux/api/corporatePlans';
import NoResultsFound from '../../shared/common/NoResultsFound';

const CorporatePlans = () => {
    // REDUX
    const ondemand_id = useSelector(state => state.user.user.id);

    // STATE
    const [participants, setParticipants] = useState(null);
    const [search, setSearch] = useState('');

    const [filtersOpenModal, setFiltersOpenModal] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [program, setProgram] = useState('');
    const [optionsPrograms, setOptionsPrograms] = useState(null);
    const [progress, setProgress] = useState({
        pending: false,
        in_progress: false,
        finished: false,
    });

    //filtro de estado (progress) y nombre de programa (por iniciar, en curso, finalizado)
    const { filteredFinalData } = useFilterCorporateParticipants(filteredData, program, progress);

    // EFFECTS
    useEffect(() => {
        if (participants === null) {
            getPlansParticipants(ondemand_id);
        } else {
            setFilteredData(participants?.length >= 0 ? participants : []);
        }
        // eslint-disable-next-line
    }, [participants]);

    useEffect(() => {
        if (search.length !== 0) {
            searchParticipants(filteredData, search);
        } else {
            setFilteredData(participants?.length >= 0 ? participants : []);
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (filteredFinalData) {
            setFilteredData(filteredFinalData);
        }
        // eslint-disable-next-line
    }, [program, progress]);

    // FUNCTIONS

    const getPlansParticipants = async ondemand_id => {
        const response = await getParticipants({ ondemand_id });
        if (response.code !== 200) {
            console.log('error :>> ', response);
        } else {
            setParticipants(response.response.corporate_plan_data);
            setOptionsPrograms(response.response.programs_names);
        }
    };

    //busqueda por nombre o email de participante
    function handleSearch(e) {
        setSearch(e.target.value);
    }
    const searchParticipants = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (
                item.student_name?.toLowerCase().includes(search.toLowerCase()) ||
                item?.student_email?.toLowerCase().includes(search.toLowerCase())
            ) {
                newArray.push(item);
            }
        });
        setFilteredData(newArray.length === 0 ? [] : newArray);
    };

    const handleOpenModal = () => {
        setFiltersOpenModal(true);
    };

    const handleCloseModal = () => {
        setFiltersOpenModal(false);
    };

    const filtersSelected = (state, program) => {
        //confirmada la nueva seleccion de filtros seteo total de participantes
        setFilteredData(participants?.length >= 0 ? participants : []);
        setProgress(state);
        setProgram(program);
        handleCloseModal();
    };

    const handleClean = () => {
        setProgress({
            pending: false,
            in_progress: false,
            finished: false,
        });
        setProgram('');
        setSearch('');
        setFilteredData(participants?.length >= 0 ? participants : []);
        handleCloseModal();
    };

    // RETURN
    return (
        <>
            <Layout header={<Header route="Planes corporativos" />}>
                {participants === null || filteredData === null || optionsPrograms === null ? (
                    <UsersSkt />
                ) : (
                    <HeaderWrapper>
                        <RowWrapper>
                            <InputIcon value={search} onChange={handleSearch} placeholder={'Buscar estudiante por nombre o correo'} />
                            <ButtonFilledDark
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<Icon icon="fluent:filter-32-regular" width="24" height="24" />}
                                onClick={() => handleOpenModal()}>
                                <span>Filtrar</span>
                            </ButtonFilledDark>
                        </RowWrapper>
                        {filteredData.length > 0 ? <TableCorporatePlans id="table-users" rows={filteredData} /> : <NoResultsFound />}
                    </HeaderWrapper>
                )}
            </Layout>
            {filtersOpenModal && (
                <ModalForm isOpen={filtersOpenModal} handleClose={handleCloseModal} banner={'transparent'} title={''}>
                    <FilterForm
                        filtersSelected={filtersSelected}
                        optionsPrograms={optionsPrograms}
                        initialProgram={program}
                        initialProgress={progress}
                        handleClean={handleClean}
                    />
                </ModalForm>
            )}
        </>
    );
};

export default CorporatePlans;
