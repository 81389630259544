import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import IndexRoute from "./routes/IndexRoute";
import PrivateRoute from "./routes/PrivateRoute";

// COMPONENTS
import Analytics from "./pages/Analytics";
import Account from "./pages/Account";
import Licenses from "./pages/Licenses";
import User from "./pages/User";
import CustomContent from "./pages/CustomContent";
import Group from "./pages/Group";
import EditLayout from "./pages/EditLayout";
// import Subjects from "./pages/Subjects";
import NewPost from "./pages/NewPost";
import EditPost from "./pages/EditPost";
import ViewPost from "./pages/ViewPost";
import NewBanner from "./pages/NewBanner";
import ViewBanner from "./pages/ViewBanner";
import EditBanner from "./pages/EditBanner";
import NewPopUp from "./pages/NewPopUp";
import ViewPopUp from "./pages/ViewPopUp";
import EditPopUp from "./pages/EditPopUp";
import EditCourse from "./pages/customContent/EditCourse";
import NewCourse from "./pages/customContent/NewCourse";
import CoursesPage from "./pages/customContent/CoursesPage";
import XtraContent from "./pages/additionalContent/XtraContent";
import RoutesPage from "./pages/customContent/RoutesPage";
import NewRoute from "./pages/customContent/NewRoute";
import EditRoute from "./pages/customContent/EditRoute";
import Users from "./pages/Users/Users";
import Resources from "./pages/Resources/Resources";
import EditResource from "./pages/Resource/EditResource";
import NewResource from "./pages/Resource/NewResource";
import Groups from "./pages/Groups/Groups";
import CorporatePlans from "./pages/CorporatePlans/CorporatePlans";


const Routes = () => {
	return (
		<Switch>
			<IndexRoute exact path="/" />
			<PrivateRoute exact path="/analytics" component={Analytics} />
			<PrivateRoute exact path="/content" component={CoursesPage} />
			<PrivateRoute exact path="/subjects" component={RoutesPage} />
			<PrivateRoute exact path="/additional-content" component={XtraContent} />
			<PrivateRoute exact path="/users" component={Users} />
			<PrivateRoute exact path="/resources" component={Resources} />
			<PrivateRoute exact path="/corporate-plans" component={CorporatePlans} />

			<PrivateRoute exact path="/groups" component={Groups} />
			<PrivateRoute exact path="/account" component={Account} />
			<PrivateRoute exact path="/licenses" component={Licenses} />
			<PrivateRoute exact path="/newpost" component={NewPost} />
			<PrivateRoute exact path="/newbanner" component={NewBanner} />
			<PrivateRoute exact path="/new-pop-up" component={NewPopUp} />
			<PrivateRoute exact path="/new-custom-course" component={NewCourse} />
			<PrivateRoute exact path="/new-custom-route" component={NewRoute} />
			<PrivateRoute exact path="/new-custom-resource" component={NewResource} />

			{/** Dynamics */}
			<PrivateRoute exact path="/group/:id" component={Group} />
			<PrivateRoute exact path="/user/:id" component={User} />
			<PrivateRoute exact path="/edit-layout" component={EditLayout} />
			<PrivateRoute exact path="/mi-curso/:id" component={CustomContent} />

			<PrivateRoute exact path="/articulo/:id" component={ViewPost} />

			<PrivateRoute exact path="/articulo/:id" component={ViewPost} />
			<PrivateRoute exact path="/edit-article/:id" component={EditPost} />

			<PrivateRoute exact path="/banner/:id" component={ViewBanner} />

			<PrivateRoute exact path="/banner/:id" component={ViewBanner} />
			<PrivateRoute exact path="/edit-banner/:id" component={EditBanner} />

			<PrivateRoute exact path="/pop-up:id" component={ViewPopUp} />
			<PrivateRoute exact path="/edit-pop-up/:id" component={EditPopUp} />

			<PrivateRoute exact path="/edit-custom-course/:id" component={EditCourse} />
			<PrivateRoute exact path="/edit-custom-route/:id" component={EditRoute} />
			<PrivateRoute exact path="/edit-custom-resource/:id" component={EditResource} />


			<Route render={() => <Redirect to="/" />} />
		</Switch>
	);
};

export default Routes;
