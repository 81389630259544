import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import LinearProgressDiv from '../../../shared/common/LinearProgress';
import styled from 'styled-components';
import SubjectsStatusWrapper from './SubjectsStateWrapper';
import Text from '../../../shared/common/Text';
import { ButtonOutlinedDark } from '../../../styled-components/buttons.styled.components';
import { Download } from '@mui/icons-material';
import * as XLSX from 'xlsx';

const headCells = [
    { id: 'nombre', label: 'Estudiante', width: '20%' },
    { id: 'email', label: 'Programa', width: '20%' },
    { id: 'fecha_alta', label: 'Progreso de programa', width: '20%' },
    { id: 'estado', label: 'Estado de las asignaturas', width: '40%' },
];

const TableCorporatePlans = props => {
    const { rows } = props;

    // STATE
    const [page, setPage] = useState(0);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const exportToExcel = () => {
        const data = rows.map(row => ({
            Estudiante: row.student_name,
            Email: row.student_email,
            Programa: row.program_name,
            'Progreso de programa': row.program_progress_data.progress,
            'Estado de las asignaturas': `En curso: ${row.program_progress_data.in_progress}, Finalizadas: ${row.program_progress_data.finished}, Por iniciar: ${row.program_progress_data.pending}`,
        }));
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Corporate Plans');
        XLSX.writeFile(workbook, 'CorporatePlans.xlsx');
    };

    // RETURN
    return (
        <TableCorporatePlansWrapper>
            <TableContainerStyle>
                <Table aria-labelledby="TableCorporatePlans">
                    <TableHead>
                        <TableRowHeader>
                            {headCells.map(headCell => (
                                <StyledTableCell key={headCell.id} width={headCell.width}>
                                    {headCell.label}
                                </StyledTableCell>
                            ))}
                        </TableRowHeader>
                    </TableHead>
                    <TableBody>
                        {rows.slice(page * 50, page * 50 + 50).map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <>
                                    <TableRowBody
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                        onMouseEnter={() => setHoveredRowIndex(index)}
                                        onMouseLeave={() => setHoveredRowIndex(null)}>
                                        <TableCell component="th" id={labelId} scope="row" padding="default">
                                            <Text fontSize="16px" fontWeight="700" fontcolor="#1D1D1B" lineheight="19px">
                                                {row.student_name}
                                            </Text>
                                            <Text fontSize="12px" fontWeight="400" fontcolor="#535353" lineheight="30px">
                                                {row.student_email}
                                            </Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text fontSize="14px" fontWeight="400" fontcolor="#1D1D18" lineheight="24px">
                                                {row.program_name}
                                            </Text>
                                        </TableCell>

                                        <TableCell>
                                            <LinearProgressDiv
                                                odd={index % 2 === 0}
                                                progress={row.program_progress_data.progress}
                                                disabledState={false}
                                                hover={hoveredRowIndex === index}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <SubjectsStatusWrapper subjectsState={row.program_progress_data} />
                                        </TableCell>
                                    </TableRowBody>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainerStyle>
            <RowFooter>
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={50}
                    page={page}
                    onChangePage={handleChangePage}
                />
                <ButtonOutlinedDark variant="outlined" color="primary" size="small" startIcon={<Download />} onClick={exportToExcel}>
                    Descargar analítica de plan corporativo
                </ButtonOutlinedDark>
            </RowFooter>
        </TableCorporatePlansWrapper>
    );
};

export default TableCorporatePlans;

const TableCorporatePlansWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 1rem;
    align-items: center;
`;

const TableContainerStyle = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    overflow-x: auto;
    border-radius: 20px 20px 0 0;
    border: 1px solid #a8a8a8;

    .MuiTableContainer-root {
        width: 100%;
        overflow-x: auto;
        border-radius: 20px 20px 0 0;
    }
`;

const TableRowBody = styled(TableRow)`
    &.MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: #e5e5e5 !important;
    }
    &:nth-child(even) {
        background-color: #f1f1f1;
    }
    &:nth-child(odd) {
        background-color: #ffffff;
    }

    th,
    td {
        font-size: 13px;
        line-height: normal;
        font-weight: 400;
        @media (max-width: 768px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50px;
            font-size: 12px;
        }
    }
`;

const TableRowHeader = styled(TableRow)`
    background-color: #f2f2f2;
    thead,
    tr,
    th {
        border-bottom: 1px solid #a8a8a8;
        font-size: 13px;
        line-height: normal;
        font-weight: 700;
        text-align: start;
        color: #222222;
        padding: 8px 12px;
    }
`;

const StyledTableCell = styled(TableCell)`
    max-width: ${props => props.width}px;
`;

const RowFooter = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 2rem;
    padding: 1rem;
`;
