import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SubjectsStatusWrapper = props => {
    const { subjectsState } = props;
    const [arrayCount, setarrayCount] = useState([]);

    useEffect(() => {
        if (subjectsState !== null && subjectsState !== false) {
            transformObject(subjectsState);
        }
    }, [subjectsState]);

    const transformObject = object => {
        const result = Object.keys(object).map(key => ({
            name: key,
            value: object[key],
        }));
        const arrayNew = result.slice(1, 4);
        //objeto inicial {
        // "progress": 72,
        // "in_progress": 1,
        // "finished": 0,
        // "pending": 0,
        // "total": 1
        // } =>> Solo necesito posiciones del 1 al 3
        if (arrayNew.length > 0) {
            setarrayCount(arrayNew);
        } else {
            setarrayCount(null);
        }
    };

    const getChipProps = state => {
        switch (state) {
            case 'pending':
                return { label: 'Por iniciar', strongColor: '#C29F43', lightColor: '#F9F5EC' };
            case 'in_progress':
                return { label: 'En curso', strongColor: '#5E80DB', lightColor: '#F7F8FD' };
            case 'finished':
                return { label: 'Finalizadas', strongColor: '#28A986', lightColor: '#F7FDFB' };
            default:
                return null;
        }
    };

    return (
        <WrapperStatus>
            {arrayCount.map((state, index) => {
                const chipProps = getChipProps(state.name);
                return (
                    <Button variant="outlined" key={index} strongColor={chipProps.strongColor} lightColor={chipProps.lightColor}>
                        <strong>{state.value}</strong>
                        {''} {chipProps.label}
                    </Button>
                );
            })}
        </WrapperStatus>
    );
};

export default SubjectsStatusWrapper;

const WrapperStatus = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0.5rem;
    padding: 0.5rem;
    max-width: 400px;
    @media (max-width: 1024px) {
        flex-direction: column;
        width: 100%;
    }
`;

const Button = styled.span`
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    border-radius: 100px;
    gap: 8px;
    padding: 8px 16px;
    background-color: ${({ lightColor }) => lightColor};
    color: ${({ strongColor }) => strongColor};
    border: ${({ strongColor }) => `1px solid ${strongColor}`};
    /* box-shadow: 0px 8px 24px -4px #18274b14, 0px 6px 12px -6px #18274b1f; */
    @media (max-width: 1024px) {
        padding: 2px 5px;
    }
`;
