import { useMemo } from 'react';

const useFilterCorporateParticipants = (participants, program, progress) => {
    const filteredFinalData = useMemo(() => {
        const filtrosActivos = Object.entries(progress)
            .filter(([_, value]) => value)
            .map(([key, _]) => key);

        let filteredParticipants = participants;

        // Si todos los filtros de progreso están activos y no hay filtro de programa, retorna todos
        if (filtrosActivos.length === 3 && program === '') {
            return filteredParticipants;
        }

        // Filtrado por progreso
        switch (filtrosActivos.join('_')) {
            case 'pending':
                filteredParticipants = participants.filter(item => item.program_progress_data.progress === 0);
                break;
            case 'in_progress':
                filteredParticipants = participants.filter(
                    item => item.program_progress_data.progress > 0 && item.program_progress_data.progress < 100,
                );
                break;
            case 'finished':
                filteredParticipants = participants.filter(item => item.program_progress_data.progress === 100);
                break;
            case 'pending_in_progress':
                filteredParticipants = participants.filter(item => item.program_progress_data.progress < 100);
                break;
            case 'pending_finished':
                filteredParticipants = participants.filter(
                    item => item.program_progress_data.progress === 0 || item.program_progress_data.progress === 100,
                );
                break;
            case 'in_progress_finished':
                filteredParticipants = participants.filter(item => item.program_progress_data.progress > 0);
                break;
            default:
                filteredParticipants = participants;
                break;
        }

        // Filtrado por programa
        if (program) {
            filteredParticipants = filteredParticipants.filter(item => item.program_name?.toLowerCase().includes(program.toLowerCase()));
        }

        return filteredParticipants;
    }, [participants, program, progress]);

    return { filteredFinalData };
};

export default useFilterCorporateParticipants;
